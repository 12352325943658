import CheckIcon from "../images/icon/check.svg";
import Utm from "../components/_global/utm";

// images
import TopBackground from "../images/medical-wear-facemasks-covid19-head.jpeg";
import Slide1 from "../images/slides/face/medical-wear-search-1.png";
import Slide2 from "../images/slides/face/medical-wear-search-2.png";
import Slide3 from "../images/slides/face/medical-wear-rfq.png";
import Slide4 from "../images/slides/face/facemasks-medical-wear-manage-quotes.png";
import Slide5 from "../images/slides/face/medical-wear-inspiration-light-box.png";
import Slide6 from "../images/slides/face/medical-wear-showrooms.png";
import Partners from "../images/centered-partners.jpg";

export const pageTexts = (t) => {
  return {
    topInfo: {
      title: {
        text: t("face-masks-and-medical-wear.topTitle"),
        colorClass: "colorLight",
      },
      subtitle: {
        text: t("face-masks-and-medical-wear.topSubtitle"),
        colorClass: "colorLight",
      },
      cta: {
        text: t("face-masks-and-medical-wear.joinFree"),
        url:process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`),
        type: "mainColor",
      },
      bottomDiagonal: true,
      opacity: true,
      image: {
        src: TopBackground,
        alt: t("face-masks-and-medical-wear.imgAlt"),
      },
    },
    pageContent: [
      {
        info: {
          title: {
            text: t("face-masks-and-medical-wear.findSupplier"),
            colorClass: "colorDark",
          },
          icon: { fontAwesome: false, src: CheckIcon, alt: t("homepage.checkAlt") },
          separator: true,
          item: {
            text: [
              t("face-masks-and-medical-wear.accessNetwork"),
              t("face-masks-and-medical-wear.selectProfiles"),
              t("face-masks-and-medical-wear.identifyLogic"),
              t("face-masks-and-medical-wear.filterCerts"),
            ],
            colorClass: "colorDark",
          },
          bottomDiagonal: true,
          pushTop: true,
          topDiagonal: true,
          backgroundGrey: true,
          customPB: true,
          customPBText: true,
          images: [Slide1, Slide2],
        },
        isTextFirst: true,
        cta: {
          text: t("face-masks-and-medical-wear.seeResults"),
          url:process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`),
          type: "mainColor",
          extraClass: "bigWidth",
        },
      },
      {
        info: {
          title: {
            text: t("face-masks-and-medical-wear.getQuotes"),
            colorClass: "colorDark",
          },
          icon: { fontAwesome: false, src: CheckIcon, alt: t("homepage.checkAlt") },
          separator: true,
          item: {
            text: [
              t("face-masks-and-medical-wear.placeRequests"),
              t("face-masks-and-medical-wear.receiveOffers"),
              t("face-masks-and-medical-wear.seeRequests"),
              t("face-masks-and-medical-wear.makeRFQs"),
            ],
            colorClass: "colorDark",
          },
          bottomDiagonal: false,
          topDiagonal: false,
          backgroundGrey: false,
          customPB: false,
          customPBText: true,
          images: [Slide3, Slide4],
        },
        isTextFirst: false,
        cta: {
          text: t("face-masks-and-medical-wear.signupFree"),
          url:process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`),
          type: "mainColor",
          extraClass: "bigWidth",
        },
      },
      {
        info: {
          title: {
            text: t("face-masks-and-medical-wear.exploreShowrooms"),
            colorClass: "colorDark",
          },
          icon: { fontAwesome: false, src: CheckIcon, alt: t("homepage.checkAlt") },
          separator: true,
          item: {
            text: [
              t("face-masks-and-medical-wear.virtualShowrooms"),
              t("face-masks-and-medical-wear.targetFilters"),
              t("face-masks-and-medical-wear.manageSamples"),
              t("face-masks-and-medical-wear.requestPrices"),
            ],
            colorClass: "colorDark",
          },
          bottomDiagonal: true,
          pushTop: false,
          topDiagonal: true,
          backgroundGrey: true,
          customPB: true,
          customPBText: true,
          images: [Slide5, Slide6],
        },
        isTextFirst: true,
        cta: {
          text: t("face-masks-and-medical-wear.signupFree"),
          url:process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`),
          type: "mainColor",
          extraClass: "bigWidth",
        },
      },
    ],
    aboutUs: {
      title: {
        text: t("face-masks-and-medical-wear.aboutUs"),
        colorClass: "colorDark",
        extraClass: "font40",
      },
      text: {
        text: t("face-masks-and-medical-wear.aboutUsText"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
      separator: true,
    },
    partnersTitle: {
      title: {
        text: t("centeredPage.partnersTitle"),
        colorClass: "colorDark",
        extraClass: "font40",
      },
    },
    partners: {
      src: Partners,
      alt: t("centeredPage.partnersAlt"),
    },
  };
};
