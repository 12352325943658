/**
 * Foursource page
 * https://foursource.com/face-masks-and-medical-wear
 */

import * as React from "react";
import { withTrans } from "../i18n/withTrans";

import { pageTexts } from "../content/face-masks-and-medical-wear";
import { associates } from "../content/pages/associatesPrincipal";

// components
import Layout from "../components/layout";
import TextImgBackgroundFull from "../components/textImgBackgroundFull";
import Associates from "../components/_page/associates";
import SideBySide from "../components/sideBySide";
import TitleSeparator from "../components/titleSeparator";

class faceMasksPage extends React.Component {
  constructor(props) {
    super(props);
    this.t = props.t;
    this.text = pageTexts(this.t);
    this.associates = associates(this.t);
  }

  render() {
    return (
      <>
        <Layout pageName="face-masks-and-medical-wear" whiteMenu={false}>
          <TextImgBackgroundFull info={this.text.topInfo}></TextImgBackgroundFull>
          {this.text.pageContent.map((content, i) => (
            <SideBySide key={i} info={content.info} isTextFirst={content.isTextFirst} cta={content.cta}></SideBySide>
          ))}
          <div className="paddingSidesBigger pt-50 pb-50 text-center">
            <TitleSeparator info={this.text.aboutUs}></TitleSeparator>
          </div>
          <div className="pt-60 pb-50 topBottomDiagonal backgroundGrey">
            <Associates info={this.associates.cardsInfo}></Associates>
          </div>
          <div className="pt-30 text-center pb-20">
            <TitleSeparator info={this.text.partnersTitle}></TitleSeparator>
            <img className="w-100 paddingSidesBig pt-50 pb-50" src={this.text.partners.src} alt={this.text.partners.alt} />
          </div>
        </Layout>
      </>
    );
  }
}

export default withTrans(faceMasksPage);
